;(function($, window, document, undefined) {
	var $win = $(window);
	var $doc = $(document);
	var elem = document.getElementById('viewportID');
	var isTouch = ( ('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch ) || false;

	$doc.ready(function() {
		$(elem).attr('content', 'width=device-width');

		if ( ( $win.width() <= 1024 ) && ( $win.width() > 767 ) ) {
			$(elem).attr('content', 'width=1200');
		} else {
			$(elem).attr('content', 'width=device-width');
		}

		$('.custom-select').selectBox({
			mobile: true
		}).change(function () {
			window.location = $(this).val();
		});

		//  Main slider
		$('.slider .slides').slick({
			infinity: true,
			autoplay: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			dots: false,
			arrows: false,
			fade: true,
			cssEase: 'linear',
			adaptiveHeight: true
		});

		//Gallery Widget Slider

		$('.images--thumbnails').slick({
			dots: false,
			arrows: false,
			slidesToShow: 3,
			slidesToScroll: 1,
			autoplay: true,
			asNavFor: '.images--slides',
			focusOnSelect: true,
		})

		$('.images--slides').slick({
			dots: false,
			arrows: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			asNavFor: '.images--thumbnails',
		})

		/*$('.images--thumbnails').slick({
			dots: true,
			arrows: false,
			infinite: true,
			speed: 500,
			fade: false,
			slide: 'div',
			cssEase: 'linear',
			centerMode: true,
			slidesToShow: 1,
			variableWidth: false,
			autoplay: true,
			autoplaySpeed: 4000,
			// responsive: [{
			// 	breakpoint: 800,
			// 	settings: {
			// 		centerMode: false,
			// 		centerPadding: '40px',
			// 		variableWidth: false,
			// 		slidesToShow: 1,
			// 		dots: true
			// 	},
			// 	breakpoint: 1200,
			// 	settings: {
			// 		arrows: false,
			// 		centerMode: false,
			// 		centerPadding: '40px',
			// 		variableWidth: false,
			// 		slidesToShow: 1,
			// 		dots: true

			// 	}
			// }],
			// customPaging: function (slider, i) {
			// 	return '<button class="tab">' + $('.images--thumbnails div:nth-child(' + (i + 1) + ')').html() + '</button>';
			// }
		});*/

		// Expand mobile navigation
		$('.nav-trigger').on('click', function(e){
			e.preventDefault();

			$(this).toggleClass('active');
			$('.nav-wrapper').slideToggle();
		});

		// Expand dropdown on touch devices
		$('.nav ul > li.menu-item-has-children > a').on('click', function  (e) {
			var $this = $(this),
				$parent = $this.parent();

			var $ul = $parent.find('.dd');

			if (!$parent.is('.active')) {
				e.preventDefault();

				$parent.toggleClass('active')
					.siblings()
						.removeClass('active');

				$ul.toggleClass('expanded')
					$ul.parent()
						.siblings()
						.find('.dd')
						.removeClass('expanded')
			}
		});

		$('.btn-hours-triiger').on( 'click', function( e ) {
			e.preventDefault();

			$(this).toggleClass( 'expanded' );
			$( '.hours-callout-text' ).slideToggle();
		} );

		$('a[href*="youtube.com"]').magnificPopup({
			type: 'iframe'
		});
	});

})(jQuery, window, document);
